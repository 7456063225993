<template>
  <div class="payment_page">
    <div class="payment_top_title">
      <div class="payment_title">付款方式</div>
    </div>
    <div class="payment_main">
      <div class="payment_li">
        <div class="text">
          <div class="img">
            <img src="@/assets/img/paypal1.png" alt="" />
          </div>
          PayPal付款
        </div>
        <div>
          <!-- <button @click="paymentClick(1)" v-if="isOpen.paypal">打开</button> -->
          <div>
            <!-- <button class="close">关闭</button> -->
            <button @click="paymentClick(1)">修改</button>
          </div>
        </div>
      </div>
      <div class="payment_li">
        <div class="text">
          <div class="img">
            <img src="@/assets/img/银行卡.png" alt="" />
          </div>
          企业银行信息
        </div>
        <div>
          <button @click="bankClick" v-if="isOpen.bank">打开</button>
          <div v-else>
            <button @click="bankClose" class="close">关闭</button>
            <button @click="bankClick">修改</button>
          </div>
        </div>
      </div>
      <div class="payment_li">
        <div class="text">
          <div class="img">
            <img src="@/assets/img/二维码.png" alt="" />
          </div>
          二维码付款
        </div>
        <div>
          <button @click="paymentClick(3)" v-if="isOpen.code">打开</button>
          <div v-else>
            <button @click="codeClose" class="close">关闭</button>
            <button @click="paymentClick(3)">修改</button>
          </div>
        </div>
      </div>
      <div class="payment_box" v-if="payment_li !== 0">
        <div class="payment_box_close" @click="boxClose">x</div>
        <div class="payment_li_box" v-if="payment_li === 1">
          <input
            type="email"
            v-model="collectionEmail"
            placeholder="请填写您的PayPal收款电子邮件。"
          />
          <button @click="paypalSubmit">提交</button>
        </div>
        <!-- <div class="payment_li_box" v-if="payment_li === 2">
          <input type="text" v-model="nickName" placeholder="请填入姓名" />
          <input
            type="numbel"
            v-model="creditCardNumbe"
            placeholder="请填写信用卡号码"
          />

          <input
            type="text"
            v-model="collectionBrand"
            placeholder=" Please fill in your collectionBrand"
          />

          <button @click="bankSubmit">Submit</button>
        </div> -->
        <div class="payment_li_box" v-if="payment_li === 3">
          <div class="blh_graphic_add blh_graphic_add_video">
            <div class="add_before">
              <div @click="addClick"></div>
              <div>收款二维码</div>
              <input type="file" @change="inputChange($event, 'video')" />
            </div>
          </div>
          <button @click="codeSubmit">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  openBrandPay,
  openQrcodePay,
  closeBrandPay,
  closeQrcodePay,
  getBusinessPayType,
  shopMyInfo,
  openPaypalPay,
} from "@/api/business.js";
import { upload } from "@/api/other";

export default {
  name: "Payment",
  data() {
    return {
      businessId: sessionStorage.getItem("businessId"), // 商家id
      isOpen: { paypal: false, bank: true, code: true }, // 开通或关闭
      payment_li: 0, // 选择对应的弹框
      collectionEmail: "", // paypal账号
      nickName: "", // 收款人姓名
      creditCardNumbe: "", // 收款银行卡
      collectionBrand: "", // 收款银行
      address: "", // 收款码地址
    };
  },
  components: {},
  computed: {},
  watch: {},
  activated() {
    // 获取缓存中的商户id
    this.shopMyInfo();
    this.getBusinessPayType(this.businessId);
  },
  mounted() {
    // this.businessId = sessionStorage.getItem("businessId");
  },
  methods: {
    // 获取商家开通的收款方式
    getBusinessPayType(businessId) {
      getBusinessPayType(businessId).then((res) => {
        if (res.code == 200) {
          // this.$message({ message: "Get success", type: "success" });
          this.isOpen.paypal = !res.data.isPayPal;
          this.isOpen.bank = !res.data.isBrand;
          this.isOpen.code = !res.data.isQrcode;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    // 获取商家信息
    shopMyInfo() {
      shopMyInfo().then((res) => {
        if (res.code == 200) {
          // this.$message({ message: "Get success", type: "success" });
          this.businessId = res.data.businessId;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    paymentClick(n) {
      this.payment_li = n;
    },
    bankClick() {
      this.$router.push({
        path: "/openbank",
        query: {
          bool: false,
          box8: false,
          phoneHomeImg: false,
          noBusiness: false,
        },
      });
    },
    boxClose() {
      this.payment_li = 0;
    },
    // paypal提交
    paypalSubmit() {
      if (!this.collectionEmail) {
        this.$message({
          message: "请输入你的paypal账号",
          type: "warning",
        });
        return;
      } else {
        openPaypalPay(this.collectionEmail).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.payment_li = 0;
            this.isOpen.bank = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      }
    },
    // bank提交
    bankSubmit() {
      if (!this.nickName) {
        this.$message({
          message: "请输入姓名",
          type: "warning",
        });
        return;
      }
      if (!this.creditCardNumbe) {
        this.$message({
          message: "请输入卡号",
          type: "warning",
        });
        return;
      }
      if (!this.collectionBrand) {
        this.$message({
          message: "请输入银行名称",
          type: "warning",
        });
        return;
      } else {
        openBrandPay({
          nickName: this.nickName,
          creditCardNumbe: this.creditCardNumbe,
          collectionBrand: this.collectionBrand,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.payment_li = 0;
            this.isOpen.bank = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      }
    },
    // code提交
    codeSubmit() {
      if (!this.address) {
        this.$message({
          message: "请上传二维码图片",
          type: "warning",
        });
        return;
      } else {
        this.address = this.$baseUrl + this.address;
        openQrcodePay({
          address: this.address,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.payment_li = 0;
            this.isOpen.code = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      }
    },
    // 关闭银行卡支付
    bankClose() {
      closeBrandPay().then((res) => {
        if (res.code == 200) {
          this.$message({ message: "关闭成功", type: "success" });
          this.isOpen.bank = true;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    // 关闭二维码卡支付
    codeClose() {
      closeQrcodePay().then((res) => {
        if (res.code == 200) {
          this.$message({ message: "关闭成功", type: "success" });
          this.isOpen.code = true;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    // 添加图片/视频
    addClick(e) {
      let parent = e.currentTarget.parentNode; // 获取父盒子
      let input = parent.lastChild; // 获取input标签
      input.click(); // 上传文件
    },
    // 添加后父盒子变化
    addAfterParent(e) {
      let parent = e.currentTarget.parentNode; // 获取父节点
      parent.classList.replace("add_before", "add_after"); // 父盒子样式替换
      let div = document.createElement("div"); // 创建 删除盒子 节点
      parent.insertBefore(div, e.currentTarget); // 添加到父盒子
      if (document.documentElement.clientWidth > 1080) {
        div.classList.add("blh_add_delete", "icon-shanchu", "iconfont"); // 添加样式
        parent.addEventListener("mouseenter", function () {
          div.style.display = "block"; // 鼠标经过显示
        });
        parent.addEventListener("mouseleave", function () {
          div.style.display = "none"; // 鼠标离开隐藏
        });
      } else {
        div.classList.add("blh_add_delete", "icon-cuocha_kuai", "iconfont"); // 添加样式
      }

      div.addEventListener("click", this.deletefn);
    },
    deletefn() {
      let div = document.querySelector(".blh_add_delete");
      let parent = div.parentNode; // 获取父节点
      div.nextElementSibling.remove();
      parent.classList.replace("add_after", "add_before");
      div.remove();
      for (let index = 0; index < 2; index++) {
        parent.children[index].style.display = "block";
      }
      this.address = "";
    },
    // 添加后内容变化
    addAfterContent(e, file, content) {
      let parent = e.currentTarget.parentNode; // 获取父节点

      for (let index = 0; index < 2; index++) {
        parent.children[index].style.display = "none";
      }
      e.currentTarget.parentNode.insertBefore(content, e.currentTarget); // 将content添加到input前面
      let style = "blh_add_" + content.tagName.toLowerCase(); // 拼接样式名称
      content.classList.add(style); // 添加样式
      // this.addAfterParent(e);
      // 获取路径
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (res) {
        // 赋值
        content.src = res.target.result;
      };
      // 创建完将value赋值为空，解决删除后再添加无效的问题
      e.currentTarget.value = "";
    },
    // 上传之后
    inputChange(e) {
      let file = e.currentTarget.files[0]; // 获取上传内容
      let type = file.name.split(".").pop(); // 获取文件类型
      type = type.toLowerCase(); // 转换为小写
      // 判断类型
      if (
        type === "jpeg" ||
        type === "png" ||
        type === "svg" ||
        type === "bmp" ||
        type === "ico" ||
        type === "webp" ||
        type === "jpg"
      ) {
        let img = document.createElement("img"); // 创建img节点
        this.addAfterParent(e);
        this.addAfterContent(e, file, img);
        this.uploadImg(file);
      } else {
        alert("Please Upload The Image");
      }
    },
    //上传图片
    uploadImg(file) {
      let imgData = new FormData();
      imgData.append("file", file);
      //调接口，上传图片
      upload(imgData).then((response) => {
        if (response.code == 200) {
          this.address = response.data.relativeUrl;
        } else {
        }
      });
    },
  },
};
</script>

<style scoped>
.blh_graphic_add .add_before {
  width: calc(200 * 100vw / 1920);
  height: calc(200 * 100vw / 1920);
  background: #ffffff;
  border: 1px dashed #aaaaaa;
  /* margin-right: calc(10 * 100vw / 1920); */
  font-family: Roboto;
  font-weight: 400;
  color: #aaaaaa;
}
.blh_graphic_add .add_after {
  width: calc(200 * 100vw / 1920);
  height: calc(200 * 100vw / 1920);
  background: #ffffff;
  border: 1px solid #aaaaaa;
  margin-right: calc(10 * 100vw / 1920);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blh_graphic_add .add_before div:nth-of-type(1) {
  width: calc(200 * 100vw / 1920);
  height: calc(143 * 100vw / 1920);
  background-image: url(@/assets/img/添加拷贝.png);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: calc(80 * 100vw / 1920) calc(80 * 100vw / 1920);
}
.blh_graphic_add .add_before div:nth-of-type(2) {
  font-size: calc(16 * 100vw / 1920);
  /* margin-left: calc(18 * 100vw / 1920); */
  text-align: center;
  margin-top: calc(12 * 100vw / 1920);
}
.blh_graphic_add Input {
  width: 0px;
  height: 0px;
  display: none;
}
.payment_page {
  /*width: calc(890 * 100vw / 1920);*/
  width: 100%;
  /* height: calc(740 * 100vw / 1920); */
  min-height: 100%;
  background: #fff;
  border: 1px solid #ddd;
  position: relative;
}
.payment_top_title {
  background-color: #fff;
}
.payment_title {
  margin-left: calc(38 * 100vw / 1920);
  width: calc(140 * 100vw / 1920);
  height: calc(75 * 100vw / 1920);
  white-space: nowrap;
  line-height: calc(75 * 100vw / 1920);
  border-bottom: 5px solid rgba(19, 175, 135, 1);
  font-weight: bolder;
  font-size: 1.28vw;
}
.payment_main {
  border-top: 1px solid #ddd;
  padding-top: calc(40 * 100vw / 1920);
}
.payment_li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(243, 240, 240);
  padding: calc(40 * 100vw / 1920);
  font-size: calc(24 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  margin-bottom: calc(30 * 100vw / 1920);
}
.payment_li .text {
  display: flex;
  align-items: center;
}
.img {
  width: calc(40 * 100vw / 1920);
  height: calc(40 * 100vw / 1920);
  display: flex;
  align-items: center;
  margin-right: calc(20 * 100vw / 1920);
}
.img img {
  max-width: calc(40 * 100vw / 1920);
  max-height: calc(40 * 100vw / 1920);
}
.payment_li button {
  font-size: calc(24 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  border: 1px solid #ddd;
  background-color: rgba(19, 175, 135, 1);
  color: #fff;
  padding: calc(10 * 100vw / 1920) calc(30 * 100vw / 1920);
  margin-right: calc(20 * 100vw / 1920);
}
.payment_li .close {
  border: 1px solid #ddd;
  background-color: #aaa;
  color: #fff;
  padding: calc(10 * 100vw / 1920) calc(30 * 100vw / 1920);
}
.payment_box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(700 * 100vw / 1920);
  height: calc(400 * 100vw / 1920);
  background-color: #fff;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payment_box_close {
  width: calc(30 * 100vw / 1920);
  height: calc(30 * 100vw / 1920);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #aaa;
  border-radius: 50%;
  font-size: calc(26 * 100vw / 1920);
  color: #aaa;
  position: absolute;
  top: calc(20 * 100vw / 1920);
  right: calc(20 * 100vw / 1920);
  cursor: pointer;
}
.payment_li_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payment_li_box input {
  width: calc(480 * 100vw / 1920);
  height: calc(50 * 100vw / 1920);
  background: #ffffff;
  border: 1px solid #aaa;
  margin-bottom: calc(30 * 100vw / 1920);
  font-size: calc(18 * 100vw / 1920);
  padding-left: calc(23 * 100vw / 1920);
}
.payment_li_box input::-webkit-input-placeholder {
  font-size: calc(18 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 300;
  color: #aaaaaa;
}
.payment_li_box button {
  font-size: calc(28 * 100vw / 1920);
  font-family: Roboto;
  font-weight: 400;
  border: 1px solid #ddd;
  background-color: rgba(19, 175, 135, 1);
  color: #fff;
  padding: calc(5 * 100vw / 1920) calc(30 * 100vw / 1920);
  margin-top: calc(30 * 100vw / 1920);
}
.file {
  width: calc(400 * 100vw / 1920);
  height: calc(200 * 100vw / 1920);
  border: 1px solid #ddd;
}
.payment_li_box .file_input {
  height: calc(10 * 100vw / 1920);
  border: none;
}
</style>
<style scoped>
@media screen and (max-width: 1080px) {
  .blh_graphic_add .add_before {
    width: calc(400 * 100vw / 1080);
    height: calc(400 * 100vw / 1080);
    background: #ffffff;
    border: 1px dashed #aaaaaa;
    margin-right: calc(20 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    margin-bottom: calc(60 * 100vw / 1080);
  }
  .blh_graphic_add .add_after {
    width: calc(400 * 100vw / 1080);
    height: calc(400 * 100vw / 1080);
    background: #ffffff;
    border: 1px solid #aaaaaa;
    margin-right: calc(20 * 100vw / 1080);
    position: relative;
    margin-bottom: calc(60 * 100vw / 1080);
  }
  .blh_graphic_add .add_before div:nth-of-type(1) {
    width: calc(400 * 100vw / 1080);
    height: calc(400 * 100vw / 1080);
    background-image: url(@/assets/img/添加拷贝.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(75 * 100vw / 1080) calc(75 * 100vw / 1080);
  }
  .blh_graphic_add .add_before div:nth-of-type(2) {
    font-size: calc(32 * 100vw / 1080);
  }
  .payment_page {
    width: 100vw;
    height: calc(1500 * 100vw / 1080);
    position: relative;
    background: #f1f1f1;
  }
  .payment_title {
    margin-left: calc(40 * 100vw / 1080);
    margin-top: calc(20 * 100vw / 1080);
    width: calc(280 * 100vw / 1080);
    height: calc(150 * 100vw / 1080);
    white-space: nowrap;
    line-height: calc(170 * 100vw / 1080);
    border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: calc(48 * 100vw / 1080);
  }
  .payment_main {
    border-top: 1px solid #ddd;
    padding-top: calc(60 * 100vw / 1080);
  }
  .payment_li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: calc(60 * 100vw / 1080);
    font-size: calc(30 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    margin-bottom: calc(30 * 100vw / 1080);
  }
  .payment_li .text {
    display: flex;
    align-items: center;
    max-width: calc(600 * 100vw / 1080);
  }
  .img {
    width: calc(80 * 100vw / 1080);
    height: calc(80 * 100vw / 1080);
    display: flex;
    align-items: center;
    margin-right: calc(30 * 100vw / 1080);
  }
  .img img {
    max-width: calc(80 * 100vw / 1080);
    max-height: calc(80 * 100vw / 1080);
  }
  .payment_li button {
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    border: 1px solid #ddd;
    background-color: rgba(19, 175, 135, 1);
    color: #fff;
    padding: calc(10 * 100vw / 1080) calc(30 * 100vw / 1080);
    margin-right: calc(20 * 100vw / 1080);
  }
  .payment_li .close {
    border: 1px solid #ddd;
    background-color: #aaa;
    color: #fff;
    padding: calc(10 * 100vw / 1080) calc(30 * 100vw / 1080);
  }
  .payment_box {
    position: absolute;
    top: 40%;
    left: 50%;
    width: calc(900 * 100vw / 1080);
    height: calc(800 * 100vw / 1080);
    background-color: #fff;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .payment_box_close {
    width: calc(60 * 100vw / 1080);
    height: calc(60 * 100vw / 1080);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #aaa;
    border-radius: 50%;
    font-size: calc(36 * 100vw / 1080);
    color: #aaa;
    position: absolute;
    top: calc(40 * 100vw / 1080);
    right: calc(40 * 100vw / 1080);
    cursor: pointer;
  }
  .payment_li_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .payment_li_box input {
    width: calc(750 * 100vw / 1080);
    height: calc(100 * 100vw / 1080);
    background: #ffffff;
    border: 1px solid #aaa;
    margin-bottom: calc(50 * 100vw / 1080);
    font-size: calc(36 * 100vw / 1080);
    padding-left: calc(46 * 100vw / 1080);
  }
  .payment_li_box input::-webkit-input-placeholder {
    font-size: calc(36 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 300;
    color: #aaaaaa;
  }
  .payment_li_box button {
    font-size: calc(40 * 100vw / 1080);
    font-family: Roboto;
    font-weight: 400;
    border: 1px solid #ddd;
    background-color: rgba(19, 175, 135, 1);
    color: #fff;
    padding: calc(10 * 100vw / 1080) calc(40 * 100vw / 1080);
  }
}
</style>
<style>
.blh_add_img {
  max-width: calc(180 * 100vw / 1920);
  max-height: calc(180 * 100vw / 1920);
}
.blh_add_video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.blh_add_delete {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(42 * 100vw / 1920);
  z-index: 2;
  background: #000000;
  opacity: 0.6;
  line-height: calc(42 * 100vw / 1920);
  text-align: center;
  font-size: calc(36 * 100vw / 1920);
  color: #fff;
  display: none;
}
@media screen and (max-width: 1080px) {
  .blh_add_img {
    max-width: calc(380 * 100vw / 1080);
    max-height: calc(380 * 100vw / 1080);
  }

  .blh_add_delete {
    position: absolute;
    right: calc(-15 * 100vw / 1080);
    top: 0;
    width: calc(60 * 100vw / 1080);
    height: calc(60 * 100vw / 1080);
    border-radius: 50%;
    z-index: 2;
    background: #000000;
    opacity: 0.6;
    line-height: calc(60 * 100vw / 1080);
    text-align: center;
    font-size: calc(30 * 100vw / 1080);
    color: #fff;
    display: block;
  }
}
</style>

