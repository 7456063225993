
import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.prototype.$baseUrl = 'https://etouch1api.etouch-b2b.com'
// Vue.prototype.$baseUrl = 'http://localhost:8282'
// Vue.prototype.$baseUrl = 'http://etouchapi2.xiaochengxukf.cn'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)
import locale from 'element-ui/lib/locale/lang/zh-CN'
import vueEsign from "vue-esign"
import axios from 'axios'
import { messagebox } from '../src/utils/message'
import VueSocialSharing from 'vue-social-sharing'
// Vue.use(VueSocialSharing);
Vue.prototype.$message = messagebox
Vue.prototype.$bus = new Vue()
Vue.prototype.axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI, { locale });
Vue.use(vueEsign);

Vue.use(VueSocialSharing, {
  networks: {
    tiktok: 'https://tiktok.com/?url=@url&title=@title',
    instagram: 'https://instagram-stories:/share?url=@url&title=@title',
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')



router.afterEach((to, from, next) => {
  setTimeout(() => {
    var _hmt = _hmt || [];
    (function () {
      //每次执行前，先移除上次插入的代码
      document.getElementById('51_la') && document.getElementById('51_la').remove();
      var hm = document.createElement("script");
      hm.src = "https://js.users.51.la/21492603.js";
      hm.id = "51_la";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
});


