import request from '../utils/request'

// 获取商家信息
export function shopMyInfo(data) {
  return request({
    url: '/shop/shopMyInfo',
    method: 'post',
    data
  })
}

// 修改商家信息
export function editShopMyInfo(data) {
  return request({
    url: '/shop/editShopMyInfo',
    method: 'post',
    data
  })
}

// 获取商家子账号列表
export function shopSubAccountList(data) {
  return request({
    url: '/shop/shopSubAccountList',
    method: 'post',
    data
  })
}

// 获取商家添加子账号
export function shopAddSubAccount(data) {
  return request({
    url: '/shop/shopAddSubAccount',
    method: 'post',
    data
  })
}

// 获取商家子账号详情
export function shopSubAccountDetail(id) {
  return request({
    url: '/shop/shopSubAccountDetail/' + id,
    method: 'post',
  })
}

// 获取商家修改子账号
export function shopEditSubAccount(data) {
  return request({
    url: '/shop/shopEditSubAccount',
    method: 'post',
    data
  })
}

// 商家删除子账号
export function shopDelSubAccount(id) {
  return request({
    url: '/shop/shopDelSubAccount/' + id,
    method: 'post',
  })
}

// 获取商家优惠券列表
export function shopCouponList(data) {
  return request({
    url: '/shop/shopCouponList',
    method: 'post',
    data
  })
}

// 商家添加优惠券
export function shopAddCoupon(data) {
  return request({
    url: '/shop/shopAddCoupon',
    method: 'post',
    data
  })
}

// 获取商家优惠券详情
export function shopCouponDetail(id) {
  return request({
    url: '/shop/shopCouponDetail/' + id,
    method: 'post',
  })
}

// 获取商家修改优惠券
export function shopEditCoupon(data) {
  return request({
    url: '/shop/shopEditCoupon',
    method: 'post',
    data
  })
}

// 商家删除优惠券
export function shopDelCoupon(id) {
  return request({
    url: '/shop/shopDelCoupon/' + id,
    method: 'post',
  })
}

// 获取商家订单列表
export function shopOrderList(data) {
  return request({
    url: '/shop/shopOrderList',
    method: 'post',
    data
  })
}

// 商家取消订单
export function shopCancelOrder(data) {
  return request({
    url: '/shop/shopCancelOrder',
    method: 'post',
    data
  })
}

// 商家确认线下支付订单已支付
export function confirmOrderPay(data) {
  return request({
    url: '/shop/confirmOrderPay',
    method: 'post',
    data
  })
}
// 获取商家商品列表
export function shopCommodityList(data) {
  return request({
    url: '/index/shopCommodityList',
    method: 'post',
    data
  })
}

// 获取商家商品详细列表
export function shopCommodityDetailList(data) {
  return request({
    url: '/shop/shopCommodityDetailList',
    method: 'post',
    data
  })
}

// // 获取商家商品分类列表
// export function shopAddCategory (data) {
//   return request({
//     url: '/shop/shopAddCategory',
//     method: 'post',
//     data
//   })
// }

// 商家添加商品
export function shopAddCommodity(data) {
  return request({
    url: '/shop/shopAddCommodity',
    method: 'post',
    data
  })
}

// 获取下载模板
export function downModel(data) {
  return request({
    url: '/shop/downModel',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 商品批量导入
export function importCommodity(data) {
  return request({
    url: '/shop/importCommodity',
    method: 'post',
    data
  })
}

// 添加商家商品分类
export function shopAddCategory(data) {
  return request({
    url: '/shop/shopAddCategory',
    method: 'post',
    data
  })
}

// 获取商家开通的收款方式
export function getBusinessPayType(data) {
  return request({
    url: '/consumer/getBusinessPayType/' + data,
    method: 'post',
  })
}

// 修改PayPal收款账号
export function openPaypalPay(data) {
  return request({
    url: `/shop/openPaypalPay/` + data,
    method: 'post',
  })
}

// 开通二维码支付
export function openQrcodePay(data) {
  return request({
    url: '/shop/openQrcodePay',
    method: 'post',
    data
  })
}
// 关闭二维码支付
export function closeQrcodePay(data) {
  return request({
    url: '/shop/closeQrcodePay',
    method: 'post',
    data
  })
}
// 开通银行卡支付
export function openBrandPay(data) {
  return request({
    url: '/shop/openBrandPay',
    method: 'post',
    data
  })
}

// 关闭银行卡支付
export function closeBrandPay(data) {
  return request({
    url: '/shop/closeBrandPay',
    method: 'post',
    data
  })
}

// 商品删除
export function shopDelCommodityApi(data) {
  return request({
    url: `/shop/shopDelCommodity/${data.id}`,
    method: 'post'
  })
}

// 商品详情
export function shopCommodityDetailApi(data) {
  return request({
    url: `/shop/shopCommodityDetail/${data.id}`,
    method: 'post'
  })
}

// 商品详情
export function shopCommodityDetailByIdApi(data) {
  return request({
    url: `/shop/shopCommodityDetail/` + data,
    method: 'post'
  })
}

// 商品编辑
export function shopEditCommodityApi(data) {
  return request({
    url: `/shop/shopEditCommodity`,
    method: 'post',
    data
  })
}
// 获取商品分类
export function shopCategoryListApi(data) {
  return request({
    url: `/shop/shopCategoryList/` + data,
    method: 'post'
  })
}

// 商家简介信息
export function businessIntroduction(data) {
  return request({
    url: `/other/businessIntroduction/${data}`,
    method: 'post'
  })
}

// 删除商家商品分类
export function shopDelCategory(data) {
  return request({
    url: `/shop/shopDelCategory/${data}`,
    method: 'post'
  })
}

// 商家添加或修改商家简介
export function shopAddIntroduction(data) {

  return request({
    url: `/shop/shopAddIntroduction`,
    method: 'post',
    data
  })
}

// 商家商品上下架
export function shopUpAndDownCommodity(data) {
  return request({
    url: `/shop/shopUpAndDownCommodity/` + data,
    method: 'post',
  })
}

// 商家发询盘管理列表
export function shopEnquiriesList(data) {
  return request({
    url: `/shop/shopEnquiriesList`,
    method: 'post',
    data
  })
}

// 商家审核发询盘
// export function shopEnquiriesStatus(data) {
//   return request({
//     url: `/shop/shopEnquiriesStatus`,
//     method: 'post',
//     data
//   })
// }

export function shopEnquiriesStatus(data) {
  return request({
    url: '/shop/shopEnquiriesStatus',
    method: 'post',
    data
  })
}

// 修改询盘订单状态
export function modifyEnquiriesOrder(id, status) {
  return request({
    url: `/shop/modifyEnquiriesOrder/${id}/${status}`,
    method: 'post',
  })
}

// 优惠券开启/关闭
export function shopCouponOpenOrClose(data) {
  return request({
    url: `/shop/shopCouponOpenOrClose/` + data,
    method: 'post',
  })
}

// 商家证件资料上传
export function shopInformationUpload(data) {
  return request({
    url: `/shop/shopInformationUpload`,
    method: 'post',
    data
  })
}

// 获取商家上传证件资料
export function getShopUploadInformation(data) {
  return request({
    url: `/shop/getShopUploadInformation`,
    method: 'post',
    data
  })
}

// 商家联系我们
export function contactUs(data) {
  return request({
    url: `/index/contactUs/` + data,
    method: 'post'
  })
}

// 商家修改/添加联系方式
export function editShopContact(data) {
  return request({
    url: `/shop/editShopContact`,
    method: 'post',
    data
  })
}

// 删除商家联系方式
export function delShopContact(data) {
  return request({
    url: `/shop/delShopContact/` + data,
    method: 'post'
  })
}

// 获取商家被用户加入购物车的商品
export function getShopUserShoppingCarList(data) {
  return request({
    url: `/shop/getShopUserShoppingCarList`,
    method: 'post',
    data
  })
}

// 导出商家订单
export function exportOrderList(data) {
  return request({
    url: `/shop/exportOrderList`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 商家添加/修改订单
export function shopAddOrEditOrder(data) {
  return request({
    url: `/shop/shopAddOrEditOrder`,
    method: 'post',
    data,
  })
}

// 商家帮买家下单发送收款信息邮箱
export function shopAddOrEditOrderSendEmail(data) {
  return request({
    url: `/shop/shopAddOrEditOrderSendEmail`,
    method: 'post',
    data,
  })
}

// 商家删除帮下订单
export function shopDelOrder(data) {
  return request({
    url: `/shop/shopDelOrder/` + data,
    method: 'post',
  })
}

// 商家确认帮下单已支付
export function shopConfirmOrderPay(data) {
  return request({
    url: `/shop/shopConfirmOrderPay/` + data,
    method: 'post',
  })
}

// 获取商家银行卡收款账号信息
export function getBusinessBrandCollection(data) {
  return request({
    url: `/consumer/getBusinessBrandCollection/` + data,
    method: 'post',
  })
}

// 商家添加多个订单
export function shopAddOrders(data) {
  return request({
    url: `/shop/shopAddOrders`,
    method: 'post',
    data,
  })
}

// 商家一个订单多个商品详情
export function shopOrdersDetail(data) {
  return request({
    url: `/shop/shopOrdersDetail/` + data,
    method: 'post',
  })
}

// 商家修改多个订单
export function shopEditOrders(data) {
  return request({
    url: `/shop/shopEditOrders`,
    method: 'post',
    data,
  })
}

// 查看推广人员列表
export function businessLookPromotionUserApi(data) {
  return request({
    url: `/promotion/business/businessLookPromotionUser`,
    method: 'post',
    data,
  })
}

// 查看推广人员申请列表
export function businessLookPromotionApplyApi(data) {
  return request({
    url: `/promotion/business/businessLookPromotionApply`,
    method: 'post',
    data,
  })
}

// 商家添加/修改规格
export function shopSpecificationsApi(data) {
  return request({
    url: `/shop/shopSpecifications`,
    method: 'post',
    data,
  })
}
// 商家删除规格
export function shopDelSpecificationsApi(data) {
  return request({
    url: `/shop/shopDelSpecifications/${data}`,
    method: 'post',
    data,
  })
}
// 商家查询推广记录
export function businessLookCommissionListApi(data) {
  return request({
    url: `/promotion/business/businessLookCommissionList`,
    method: 'post',
    data,
  })
}
// 商家推广申请审核
export function businessAuditApplyApi(data) {
  return request({
    url: `/promotion/business/businessAuditApply`,
    method: 'post',
    data,
  })
}
// 商家提现申请记录
export function businessPromotionCashListApi(data) {
  return request({
    url: `/promotion/business/businessPromotionCashList`,
    method: 'post',
    data,
  })
}
// 商家提现申请记录审核
export function businessAuditCashApi(data) {
  return request({
    url: `/promotion/business/businessAuditCash`,
    method: 'post',
    data,
  })
}